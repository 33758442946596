<!--
   Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
   SPDX-License-Identifier: MIT-0
-->

<mat-card fxFlex="1 1 auto" ngClass="app-card">
  <div (keydown.enter)="submit()">
  <mat-card-header>
    <mat-icon mat-card-avatar>lock</mat-icon>
    <mat-card-title>Complete Sign-In</mat-card-title>
    <mat-card-subtitle>Please enter the secret sign-in code that was 
      sent to {{ email | async }} to complete sign-in</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <form fxLayout="row wrap" fxLayoutGap="5%">
      <mat-form-field fxFlex="0 0 12%" ngClass="digit-input">
        <input matInput [formControl]="digit1" #digit1el type="tel" pattern="\d*">
      </mat-form-field>
      <mat-form-field fxFlex="0 0 12%" ngClass="digit-input">
        <input matInput [formControl]="digit2" #digit2el type="tel" pattern="\d" maxlength="1">
      </mat-form-field>
      <mat-form-field fxFlex="0 0 12%" ngClass="digit-input">
        <input matInput [formControl]="digit3" #digit3el type="tel" pattern="\d" maxlength="1">
      </mat-form-field>
      <mat-form-field fxFlex="0 0 12%" ngClass="digit-input">
        <input matInput [formControl]="digit4" #digit4el type="tel" pattern="\d" maxlength="1">
      </mat-form-field>
      <mat-form-field fxFlex="0 0 12%" ngClass="digit-input">
        <input matInput [formControl]="digit5" #digit5el type="tel" pattern="\d" maxlength="1">
      </mat-form-field>
      <mat-form-field fxFlex="0 0 12%" ngClass="digit-input">
        <input matInput [formControl]="digit6" #digit6el type="tel" pattern="\d" maxlength="1">
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-card-actions>
    <div fxFlex></div>
    <button type="submit" name="" mat-stroked-button color="primary" (keydown.enter)="submit()" (click)="submit()">
      <div fxFlex></div>
      <div *ngIf="!(busy | async)">CONTINUE</div>
      <mat-spinner *ngIf="busy | async" [diameter]="35"></mat-spinner>
      <div fxFlex></div>
    </button>
    <div fxFlex></div>
  </mat-card-actions>
</div>

  <p *ngIf="errorMessage | async">{{ errorMessage | async }}</p>
  <a href="/sign-in">Cancel</a>

</mat-card>