// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { Component, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent {

  email = new FormControl('');
  firstName = new FormControl('');
  lastName = new FormControl('');

  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;


  private busy_ = new BehaviorSubject(false);
  public busy = this.busy_.asObservable();

  private errorMessage_ = new BehaviorSubject('');
  public errorMessage = this.errorMessage_.asObservable();

  constructor(private router: Router, private auth: AuthService) { }

  private captcha="";
  public async signup() {
    this.errorMessage_.next('');
    
    if(!this.firstName.value || this.firstName.value.length<1){
      this.errorMessage_.next("First Name is required.");
      return;
    }
    if(!this.lastName.value || this.lastName.value.length<1){
      this.errorMessage_.next("Last Name is required.");
      return;
    }
    if(!this.email.value || this.email.value.length<5 || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email.value)){
      this.errorMessage_.next("Please double check the email address.");
      return;
    }    
    if(!this.captcha || this.captcha.length==0){
      this.errorMessage_.next("Please don't forget the human test :) ");
      return;
    }

    this.busy_.next(true);
    try {
      await this.auth.signUp(this.email.value, this.firstName.value, this.lastName.value, this.captcha);
      await this.auth.signIn(this.email.value, this.captcha);
      this.router.navigate(['/enter-secret-code']);
    } catch (err) {
      console.log(err);
      this.errorMessage_.next(err.message || err);
    } finally {
      this.busy_.next(false);
    }
  }


  ngOnInit() {
    this.addRecaptchaScript();
  }
 
  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6LfmAsIZAAAAAN2uv-2SQM1EZLmuHQpgztjkGyNT',
      'callback': (response) => {
        this.captcha=response;
      }
    });
  }
 
  addRecaptchaScript() {
 
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }
 
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
 
  }

}
