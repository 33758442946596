// Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { Component, ChangeDetectionStrategy, ViewChild, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { AuthService } from '../auth.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent {

  public email = new FormControl('');
  @ViewChild('email') emailelement: ElementRef;

  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;


  private busy_ = new BehaviorSubject(false);
  public busy = this.busy_.asObservable();

  private errorMessage_ = new BehaviorSubject('');
  public errorMessage = this.errorMessage_.asObservable();

  constructor(private router: Router, private auth: AuthService) { }

  private captcha="";
  public async signIn() {
    if(!this.email.value || this.email.value.length<5 || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email.value)){
      this.errorMessage_.next("Please double check the email address.");
      return;
    }

    if(!this.captcha || this.captcha.length==0){
      this.errorMessage_.next("Please don't forget the human test :) ");
      return;
    }

    this.busy_.next(true);
    this.errorMessage_.next('');
    try {
      await this.auth.signIn(this.email.value, this.captcha);
      this.router.navigate(['/enter-secret-code'], {queryParamsHandling: 'preserve'});
    } catch (err) {
      this.errorMessage_.next(err.message || err);
    } finally {
      this.busy_.next(false);
    }
  }


  ngOnInit() {
    this.addRecaptchaScript();
  }
 
  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6LfmAsIZAAAAAN2uv-2SQM1EZLmuHQpgztjkGyNT',
      'callback': (response) => {
        this.captcha=response;
      }
    });
  }
 
  addRecaptchaScript() {
 
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }
 
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
 
  }

}
