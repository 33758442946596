<!--
   Copyright 2019 Amazon.com, Inc. or its affiliates. All Rights Reserved.
   SPDX-License-Identifier: MIT-0
-->

<mat-card fxFlex="1 1 auto" ngClass="app-card">

  <mat-card-header>
    <mat-icon mat-card-avatar>lock</mat-icon>
    <mat-card-title>Register</mat-card-title>
    <mat-card-subtitle>We'd be happy to have you on board!</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <form fxLayout="column">
      <mat-form-field>
        <input matInput placeholder="First Name" [formControl]="firstName" name="firstName" autocomplete="firstName" />
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Last Name" [formControl]="lastName" name="lastName" autocomplete="lastName" />
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="E-mail" [formControl]="email" type="email" email name="email"
          autocomplete="username email" />
      </mat-form-field>
    </form>
  </mat-card-content>
  
      <mat-card-content>
        <div fxFlex></div>
        <div #recaptcha ></div>
        <div fxFlex></div>
      </mat-card-content>

  <mat-card-actions>
    <div fxFlex></div>
    <button mat-stroked-button color="primary" (click)="signup()">
      <div fxFlex></div>
      <div *ngIf="!(busy | async)">SIGN UP</div>
      <mat-spinner *ngIf="busy | async" [diameter]="35"></mat-spinner>
      <div fxFlex></div>
    </button>
    <div fxFlex></div>
  </mat-card-actions>

  <p *ngIf="errorMessage | async">{{ errorMessage | async }}</p>

  <mat-card-content>
    <div fxFlex></div>
    <p>Already signed up? <a routerLink='/sign-in' routerLinkActive="active">Sign in</a></p>
  </mat-card-content>

</mat-card>